





























































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import ChangeStorage from "@/components/storages/ChangeStorage.vue";
import { mapGetters } from "vuex";

@Component({
  components: {
    ChangeStorage
  },
  computed: mapGetters("UserModule", ["getStorage"])
})
export default class AsideMenu extends Vue {
  windowWidth: number = window.innerWidth;
  nombre = "";
  isChangingSucursal = false;
  isAddingNueces = false;

  get sidebarState(): boolean {
    return this.$store.state.sidebarOpen;
  }

  openSidebar() {
    this.$store.dispatch("setSidebarStatus", !this.$store.state.sidebarOpen);
  }
  logout() {
    localStorage.removeItem("tk");
    this.$router.go(0);
  }

  @Watch("windowWidth")
  checkOpenSidebar(val: number) {
    if (val >= 1250) {
      this.$store.dispatch("setSidebarStatus", false);
    }
  }
  mounted() {
    window.onpopstate = () => {
      this.$store.dispatch("setSidebarStatus", false);
    };
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
    if (this.$store.state.UserModule.me) {
      this.nombre = this.$store.state.UserModule.me.name;
    }
  }
}
